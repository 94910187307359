import { firestore } from "../../firebase/firestore"
import { firebaseAuth } from '../../firebase/firebaseAuth'
import { createCustomer } from '../../services/pagarme'

export const getUser = (customer_id) => {
    return firestore.collection('users').doc(customer_id).get()
};

export const getUserByEmail = (email) => {
    return firestore
        .collection('users')
        .where('email', '==', email)
        .limit(1)
        .get()
};


export const updateUser = (customer_id, data) => {
    // console.log('lead_customer_id: ', customer_id)
    data.updated_at = new Date()
    if(data.boleto_accepted_at) {
        data.boleto_accepted_at = new Date()
    }
    // console.log('user_data: ', data)
    const ref = firestore.doc(`users/${customer_id}`)
    return ref.update(data)
        .then(() => {
            return ref.get()
        })
        .catch(error => {
            console.error('Erro ao atualizar usuário')
            return error
        })
}

export const createUser = (uid, user) => {
	user.updated_at = new Date()
	user.created_at = new Date()
	if (uid) {
		const ref = firestore.collection('users')
		return ref.doc(uid).set(user)
	} else {
		const ref = firestore.collection('users')
		return ref.add(user)
	}
}

export const storeUser = async (data) => {
	if (!data.email && !data.cpf) return false
    let users = await getUserByEmail(data.email)
    // console.log('us: ', users)
	if (users.size > 0) {
		return {error: 'duplicate'}
	} else {
		// console.log('Usuário será criado!')
		//Criar usuário no auth
		let newUserAuth = await createAuthUser(data)
		// console.log('newUserAuth: ', newUserAuth)
		if (newUserAuth.user.uid) {
			//criar usuario no banco de dados
			let newUser = {
				// buyer_id: userPagarme.data.id,
				id: newUserAuth.user.uid,
				customer_id: newUserAuth.user.uid,
				...data,
				created_at: new Date(),
				updated_at: new Date(),
			}
			// const createUser = await createUser(newUserAuth.user.uid, newUser)
			return firestore.collection('users').doc(newUser.id).set(newUser)
				.then(async () => {
					//Criar usuario no pagarme
					const dataPagarme = {
						uid: newUserAuth.user.uid,
						type: 'individual',
						country: 'br',
						name: data.name,
						email: data.email,
						phone: '+55' + data.phone.replace(/[^\d]/g, '') || '',
						birthday: data.date_of_birth,
						gender: data.gender,
						cpf: data.cpf
					}
					let pagarme_user = await createCustomer(dataPagarme)
					console.log('pagarme_user', pagarme_user)
					newUser.buyer_id = pagarme_user.data.id
					return newUser
					// if (userPagarme.data.id) {
					// 	return newUser
					// } else {
					// 	return newUser
					// }
				})
				.catch(error => {
					console.error(error)
					return error
				})
		} else {
			return {error: 'Erro ao criar usuário. Entrar em contato com o suporte'}
		}

	}
}

export const updateUserByEmail = async (email, data) => {
	// console.log('email: ', data)
	const refEmail = await firestore.collection('users').where('email', '==', email).limit(1)
	refEmail.get()
		.then(userEmail => {
			if(userEmail.size > 0) {
				let doc = userEmail.docs[0]
				data.updated_at = new Date()
				// console.log('user_data: ', data)
				const ref = firestore.doc(`users/${doc.id}`)
				return ref.update(data)
					.then(() => {
						return ref.get()
					})
					.catch(error => {
						console.error('Erro ao atualizar usuário')
						return error
					})
			} else {
				return 'Usuário não encontrado para o email ' + email
			}
		})
		.catch(err => {
			console.error(err)
			return err
		})
}

export const updateReferralBalance = async (email, value) => {
	if(!value || Number(value) <=0 ) {
		console.error('Não há bonus para adicionar. Valor do Bonus: ' + value)
		return
	}
	// console.log('email: ' + email + ' - bonus:' + value)
	const refEmail = await firestore.collection('users').where('email', '==', email).limit(1)
	refEmail.get()
		.then(userEmail => {
			if(userEmail.size > 0) {
				let data = {}
				let doc = userEmail.docs[0]
				data.updated_at = new Date()
				let referral_balance = 0
				let referral_indicator = 0
				if(doc.data().referral_balance) {
					referral_balance = Number(doc.data().referral_balance) || 0
					referral_indicator = Number(doc.data().referral_indicator) || 0
				}
				referral_balance = referral_balance + Number(value)
				referral_indicator = referral_indicator + 1
				data.referral_balance = referral_balance
				data.referral_indicator = referral_indicator
				// console.log('user_data: ', data)
				const ref = firestore.doc(`users/${doc.id}`)
				return ref.update(data)
					.then(() => {
						return ref.get()
					})
					.catch(error => {
						console.error('Erro ao atualizar usuário')
						return error
					})
			} else {
				return 'Usuário não encontrado para o email ' + email
			}
		})
		.catch(err => {
			console.error(err)
			return err
		})
}

export const createAuthUser = (data) => {
	return firebaseAuth.createUserWithEmailAndPassword(data.email, data.cpf)
}
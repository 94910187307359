// import Http from './http'; // não precisa de .js

import axios from 'axios'

const token = !localStorage.getItem('token')?false:localStorage.getItem('token')
if(token)
  axios.defaults.headers.get['Authorization'] = 'Bearer ' + token

const config = {
  headers: {
    'Authorization': 'Bearer ' + token, 
    'Access-Control-Allow-Origin':'*'
  }
}
let create_customer_url = process.env.VUE_APP_PAGARME_CREATECUSTOMER
let get_transaction_url = process.env.VUE_APP_GET_PAGARME_TRANSACTION

export const createCustomer = (data) => {
  // console.log('create_customer_url: ', create_customer_url)
  return axios.post(create_customer_url, data , config);
};

export const getTransaction = (id) => {
  return axios.get(get_transaction_url + '/' + id, config);
};
<template>
  <v-container fluid grid-list-xl class="progress-container">
    <v-layout align-center justify-center column>
      <v-flex xs12 sm12 md12>
        <a href="https://enercred.com.br" target="_blank" alt="Enercred - Energia Limpa e Barata">
          <img
            src="@/assets/logo.svg"
            width="180px"
            style="padding: 5px"
            alt="Enercred - Energia Limpa e Barata"
          />
        </a>
      </v-flex>
      <v-flex v-if="loading" align-center justify-center row>
        <v-progress-circular :size="40" :width="4" color="success" indeterminate></v-progress-circular>
      </v-flex>
      <v-flex
        xs12
        sm6
        md6
        v-if="!loading && user.id && !hasCard"
        class="align-center justify-center row fill-height"
      >
        <img
          src="@/assets/icons/boleto.png"
          width="180px"
          style="padding: 5px"
          alt="Enercred - Energia Limpa e Barata"
        />
      </v-flex>
      <v-flex
        xs12
        sm6
        md6
        v-if="!loading && user.id && !hasCard"
        class="align-center justify-center row fill-height"
      >
        <h2>{{firstName}}</h2>
        <p class="subheader">
          Você optou pela forma de pagamento
          <strong>boleto</strong> para pagar por sua
          <strong>energia limpa</strong>,
          seus créditos devem chegar entre
          <strong>maio ou junho</strong>.
        </p>
        <p class="details">Enviamos um email com detalhes sobre sua economia.</p>
      </v-flex>
      <v-flex
        xs12
        sm12
        md12
        v-if="!loading && user.id && hasCard"
        class="align-center justify-center row fill-height"
      >
        <h2>{{firstName}}</h2>
        <p
          class="subheader"
        >Verificamos que você já possui um cartão de crédito cadastrado, deseja mudar sua forma de pagamento para boleto?</p>
        <v-btn
          :loading="loadingButton"
          :disable="loadingButton"
          color="success"
          @click="updateBoleto(user.id)"
        >Sim</v-btn>
      </v-flex>
      <v-flex
        v-if="!loading && !user.id"
        xs12
        sm12
        md12
        class="align-center justify-center row fill-height"
      >
        <h2>Usuário não identificado!</h2>
        <p>
          Por favor, entre em contato entre em contato com nosso time pelo telefone
          <a
            href="tel:+553135003614"
            target="_blank"
          >(31) 3500-3614</a>
          , pelo whatsapp
          <a
            href="https://wa.me/553536291633"
            target="_blank"
          >(35) 3629-1633</a>
          ou email
          <a
            href="mailto:contato@enercred.com.br?subject=Por que devo escolher a forma de Pagamento"
            target="_blank"
          >contato@enercred.com.br.</a>
        </p>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
import { getUser, updateUser } from "../../services/firebase/user"
import { getIP } from "../../services/getIP"
import { saveAnalytics } from "../../services/save_analytics"
// import axios from 'axios';
export default {
  data() {
    return {
      loading: true,
      loadingButton: false,
			hasCard: false,
			isProd: false,
			user: {},
			ipinfo: {}
    }
  },
  created() {
		// const url = 'http://ipinfo.io/json?token=dfd25b664a2884'
		// axios.get(url).then(response => {
		// 	this.ipinfo = response.data
		// 	console.log(response.data)
		// })
		this.isProd = process.env.NODE_ENV === "production"
		getIP()
			.then(response => {
				this.ipinfo = response.data
				console.log(response.data)
			})
			.catch(error => {
				console.error('Erro ao recuperar IP do usuário. Erro: ', error)
			})

    if (this.$route.query.id) {
      getUser(this.$route.query.id)
        .then(user => {
          if (user.exists) {
            if (user.data().card_default) {
              this.hasCard = true
              this.loading = false
              user = {
                id: user.id,
                ...user.data()
              }
              this.user = user
            } else {
							const data = {
								boleto_accepted_at: new Date(),
								boleto_accepted_by: "email",
								payment_type: "boleto",
								boleto_ipinfo: this.ipinfo
							}
							updateUser(user.id, data)
                .then(user => {
                  if (user.exists) {
                    user = {
                      id: user.id,
                      ...user.data()
                    }
										this.user = user
										if (this.isProd) {
											const analytics = {
												eventCategory: "onboard",
												eventAction: "ACCEPT_BOLETO",
												eventLabel: "ACCEPT_BOLETO_EMAIL",
												value: user.email,
											}
											saveAnalytics(analytics)
										}
                    this.hasCard = false
                    this.loading = false
                  } else {
                    console.error("Erro ao atualizar o usuário!")
                  }
                })
                .catch(error => {
                  console.error("Erro ao atualizar o usuário! ", error)
                  this.loading = false
                })
            }
          } else {
            // doc.data() will be undefined in this case
						console.error("Erro: Usuário não identificado!")
						this.loading = false
          }
        })
        .catch(error => {
          console.error("Erro: Usuário não identificado! ", error)
          this.loading = false
        })
    } else {
      this.loading = false
    }
  },
  methods: {
    updateBoleto(id) {
      this.loadingButton = true
      const data = {
        boleto_accepted_at: new Date(),
        boleto_accepted_by: "email",
				payment_type: "boleto",
				boleto_ipinfo: this.ipinfo
      }
      updateUser(id, data)
        .then(user => {
          if (user.exists) {
            user = {
              id: user.id,
              ...user.data()
            }
						this.user = user
						if (this.isProd) {
							const analytics = {
								eventCategory: "onboard",
								eventAction: "ACCEPT_BOLETO",
								eventLabel: "ACCEPT_BOLETO_EMAIL",
								value: user.email,
							}
							saveAnalytics(analytics)
						}
            this.hasCard = false
            this.loadingButton = false
          } else {
						console.error("Erro ao atualizar o usuário!")
						this.loadingButton = false
          }
        })
        .catch(error => {
          console.error("Erro ao atualizar o usuário! ", error)
          this.loadingButton = false
        })
    }
  },
  computed: {
    firstName() {
      if (!this.user.name) return ""
      else return this.user.name.split(" ")[0]
    }
  }
}
</script>
<style scoped>
.progress-container {
  height: 100vh;
  background: #fff;
}
strong {
  font-weight: 800;
  font-size: 16px;
}
.subheader {
  font-size: 16px;
  color: #333;
}
p {
  color: #666;
  font-size: 14px;
}
.details {
	color: #1fb440 !important;
}
h2 {
  font-weight: 900 !important;
  font-size: 32px !important;
}
</style>